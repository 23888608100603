import React from "react";
import styled from "styled-components";

import ListGroup from "Components/ListGroup";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 40px;
  .not-found-wrapper {
    padding: 32px;
  }
`;

class NotFound extends React.Component {
  render() {
    return (
      <Layout>
        <ListGroup className="not-found-wrapper">
          <h1>Not found</h1>
          <p>The page you are looking for does not exist.</p>
        </ListGroup>
      </Layout>
    );
  }
}

export default NotFound;
